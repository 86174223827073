import ExtraVO from '../BoardVO'
class Temp {
    constructor({ id = null, title = null, parent_id = null, children = [], extra_data = null, selected_index = 0, selected_id = 0, selected_data = null, done = false, expanded=['root'],saveCount=0,local_push_noti_time=null, noti_id=null }) {
        this.id = id
        this.title = title
        this.parent_id = parent_id
        if (children) {
            this.copyChildren(children);
        } else {
            this.children = children
        }
        if (extra_data != null) {
            if (extra_data.clone) {
                this.extra_data = extra_data.clone();
            } else {
                this.extra_data = new ExtraVO(extra_data);
            }
        } else {
            this.extra_data = extra_data;
        }


        this.selected_index = selected_index;
        this.selected_id = selected_id;
        if (selected_data) {
            this.selected_data = new Temp(selected_data);
        } else {
            this.selected_data = selected_data;
        }
        if(expanded){
            this.expanded = expanded.slice()
        }else{
            this.expanded=expanded
        }
        this.done = done;
        this.saveCount=saveCount
        this.local_push_noti_time = local_push_noti_time;
        this.noti_id= noti_id;
        // this.open = true;
    }
    setParentId(id) {
        this.parent_id = id
    }
    setTitle(title) {
        this.title = title;
    }
    setLocalPushTime(time) {
        this.local_push_noti_time = time;
    }
    setNotiId(noti_id) {
        this.noti_id =noti_id;
    }
    copy({ id = null, title = null, parent_id = null, children = [], extra_data = null, selected_index = 0, selected_id = 0, selected_data = null, done = false,expanded=['root'],saveCount=0 ,local_push_noti_time=null, noti_id=null }) {
        this.id = id
        this.title = title
        this.parent_id = parent_id
        if (children) {
            this.copyChildren(children);
        } else {
            this.children = children
        }
        if (extra_data != null) {
            if (extra_data.clone) {
                this.extra_data = extra_data.clone();
            } else {
                this.extra_data = new ExtraVO(extra_data);
            }
        } else {
            this.extra_data = extra_data;
        }

        this.selected_index = selected_index;
        this.selected_id = selected_id;
        if (selected_data) {
            this.selected_data = new Temp(selected_data);
        } else {
            this.selected_data = selected_data;
        }
        if(expanded){
            this.expanded = expanded.slice()
        }else{
            this.expanded=expanded
        }
        this.done = done;
        this.saveCount=saveCount
        this.local_push_noti_time = local_push_noti_time;
        this.noti_id= noti_id;


    }
    clone() {
        return new Temp(this)
    }
    copyChildren(children) {
        let length = children.length;
        this.children = []
        for (var z = 0; z < length; z++) {
            let v = new Temp({});
            v.copy(children[z]);
            this.children.push(v);
        }
    }
}
export default Temp;
