class Temp {
    constructor({ first_text_color="#000000",first_done_text_color="#000000",first_done_bg_color="#fff76b",next_text_color="#000000",next_done_text_color="#000000",next_done_bg_color="#e1ff75",next_text_color_3="#000000",next_done_text_color_3="#000000",next_done_bg_color_3="#d1ffe7",next_text_color_4="#000000",next_done_text_color_4="#000000",next_done_bg_color_4="#ffd6d6"}) {
        this.first_text_color=first_text_color;
        this.first_done_text_color=first_done_text_color;
        this.first_done_bg_color=first_done_bg_color;

        this.next_text_color=next_text_color;
        this.next_done_text_color=next_done_text_color;
        this.next_done_bg_color=next_done_bg_color;

        this.next_text_color_3=next_text_color_3;
        this.next_done_text_color_3=next_done_text_color_3;
        this.next_done_bg_color_3=next_done_bg_color_3;

        this.next_text_color_4=next_text_color_4;
        this.next_done_text_color_4=next_done_text_color_4;
        this.next_done_bg_color_4=next_done_bg_color_4;
    }
    copy({ first_text_color="#000000",first_done_text_color="#000000",first_done_bg_color="#fff76b",next_text_color="#000000",next_done_text_color="#000000",next_done_bg_color="#e1ff75",next_text_color_3="#000000",next_done_text_color_3="#000000",next_done_bg_color_3="#d1ffe7",next_text_color_4="#000000",next_done_text_color_4="#000000",next_done_bg_color_4="#ffd6d6"}) {
        this.first_text_color=first_text_color;
        this.first_done_text_color=first_done_text_color;
        this.first_done_bg_color=first_done_bg_color;

        this.next_text_color=next_text_color;
        this.next_done_text_color=next_done_text_color;
        this.next_done_bg_color=next_done_bg_color;

        this.next_text_color_3=next_text_color_3;
        this.next_done_text_color_3=next_done_text_color_3;
        this.next_done_bg_color_3=next_done_bg_color_3;

        this.next_text_color_4=next_text_color_4;
        this.next_done_text_color_4=next_done_text_color_4;
        this.next_done_bg_color_4=next_done_bg_color_4;
    }
    clone() {
        return new Temp(this)
    }
    equal({ first_text_color="#000000",first_done_text_color="#000000",first_done_bg_color="#fff76b",next_text_color="#000000",next_done_text_color="#000000",next_done_bg_color="#e1ff75",next_text_color_3="#000000",next_done_text_color_3="#000000",next_done_bg_color_3="#d1ffe7",next_text_color_4="#000000",next_done_text_color_4="#000000",next_done_bg_color_4="#ffd6d6"}) {
        if(this.first_text_color==first_text_color&&this.first_done_text_color==first_done_text_color&&this.first_done_bg_color==first_done_bg_color&&this.next_text_color==next_text_color&&this.next_done_text_color==next_done_text_color&&this.next_done_bg_color==next_done_bg_color&&this.next_text_color_3==next_text_color_3&&this.next_done_text_color_3==next_done_text_color_3&&this.next_done_bg_color_3==next_done_bg_color_3&&this.next_text_color_4==next_text_color_4&&this.next_done_text_color_4==next_done_text_color_4&&this.next_done_bg_color_4==next_done_bg_color_4){
            return true;
        }
        return false;
    }
}
export default Temp;
