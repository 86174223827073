import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Link } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import './Lnb.scss'
import InputBase from '@material-ui/core/InputBase';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { TREE_ADD_AT_FIRST, TREE_EDIT_TITLE, ACTION_TREE } from '~/actions/ActionTree';
import IconButton from '@material-ui/core/IconButton';
import { CSSTransitionGroup } from 'react-transition-group';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles({
    title: {

    },
    list: {
        width: 250,
        // height:44
    },
    list_item: {
        // width: 250,
        height: 44,
        borderTop: "solid 1px #eee",
        paddingLeft: 16,
        paddingRight: 12
        // borderBottom:"solid 1px #000"
    },
    list_header: {
        // width: 250,
        height: 44,
        marginTop: 0,
        paddingLeft: 0,
        paddingRight: 0
    },
    fullList: {
        width: 'auto',
    },
});

// export default function TemporaryDrawer({ popen, onMenuToggle, datas, onAdd, onTreeSelectChange,setTitle,onDispatch }) {
function Temp({ state, popen, onMenuToggle, onTreeSelectChange, dispatch }) {
    // const links = datas.children
    //console.log("lnb..........") 
    //console.log(state.tree);
    const links = state.tree.data_root[0]

    const classes = useStyles();
    const [dir_state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        //console.log(anchor, open)
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...dir_state, [anchor]: open });
    };
    let textfieldHandleChange = function (e, data) {
        //console.log("textfieldHandleChange:" + e.target.value)
        dispatch(TREE_EDIT_TITLE(data, e.target.value))
    }
    const actionLabel = function (type, data, value) {
        //console.log("actionLabel type:" + type + ", id:" + data.id)
        // 임시 주석
        // props.treeNodeOnChange(type, data, value);
        switch (type) {
            case "done":
                dispatch(ACTION_TREE.TREE_DONE(data, state));
                break;
            case "delete":
                dispatch(ACTION_TREE.TREE_DELETE(data));
                break;
        }
    }
    const getLink = (target) => {
        console.log("getLink")
        console.log(target)

        if (target == null) return null
        let ary = target.children;
        if (ary == null) return null;
        let container = [];
        
        for (let z = 0; z < ary.length; z++) {
            let done= (ary[z].done) ? " done" : "";
            if (ary[z].extra_data.open != 3) {
                container.push(
                    <ListItem button key={"lnbkey" + ary[z].id} className={"list-item " + classes.list_item+ done}>
                        <span className="lnb-list-done"></span>
                        {/* <InputBase
                            className={classes.title}
                            defaultValue={ary[z].extra_data.title}
                            fullWidth
                            placeholder="todo"
                            // value={currentTitle}
                            // onChange={(e)=>textfieldValueHandleChange(e)}
                            onBlur={(e) => textfieldHandleChange(e, ary[z])}
                            inputProps={{ 'aria-label': 'naked' }}
                        /> */}
                        <span className="bg" onClick={() => clickMenu(ary[z])}></span>

                        <span className="lnb-list-title">{ary[z].extra_data.title}</span>
                        {/* <span className="list-icon-wrap item right">
                        <IconButton aria-label="delete" size="small" onClick={() => actionLabel("done", ary[z])}>
                            <DoneIcon fontSize="small" />
                        </IconButton>
                        </span> */}

                        <CSSTransitionGroup
                            transitionName="iconfade"
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={1000}>
                            {
                                <span className="tool-wrap done-wrap">
                                    {!ary[z].done &&
                                        <span className="iwrap">
                                            <IconButton size="small" aria-label="delete" onClick={() => actionLabel("done", ary[z])} >
                                                <RadioButtonUncheckedIcon fontSize="small" />
                                            </IconButton>
                                        </span>
                                    }
                                    {ary[z].done &&
                                        <span className="iwrap">
                                            <IconButton size="small" aria-label="delete" onClick={() => actionLabel("done", ary[z])}>
                                                <DoneIcon fontSize="small" />
                                            </IconButton>
                                        </span>
                                    }
                                </span>
                            }
                        </CSSTransitionGroup>



                        {/* <span  onClick={()=>clickMenu(ary[z])}>go</span> */}
                        {/* <ListItemText primary={ary[z].extra_data.title} onClick={()=>clickMenu(ary[z])}/> */}
                    </ListItem>)
            }
        }
        return (
            <div>{container}</div>
        )
    }
    const clickMenu = (id) => {
        onTreeSelectChange(id);
        onClose();
    }
    const onAdd = (data) => {
        // todo select 처리
        dispatch(TREE_ADD_AT_FIRST(data))
    }
    const getHeader = () => {
        return (
            <ListItem button key='nav-util' className={"list-header " + classes.list_header}>
                <span className="header-icon-wrap item">
                    <span className="header-icon-child">
                    <IconButton aria-label="delete" size="small" onClick={() => onAdd(links)}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                    </span>
                
                </span>
                <span className="header-icon-wrap item right">
                <span className="header-icon-child">
                <IconButton aria-label="delete" size="small" onClick={() => onClose()}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
</span>
                  
                </span>
            </ListItem>


        )
    }
    const onClose = () => {
        onMenuToggle()
    }
    const list = (anchor) => (
        <div
            className={"list-wrap " + clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            // onClick={() => onMenuToggle()}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {getHeader()}
                {
                    getLink(links)
                }
            </List>

        </div>
    );
    const anchor = "top"
    return (
        <div className="lnb-cont">
            <React.Fragment key={anchor}>
                <Drawer anchor={anchor} open={popen} onClose={() => onMenuToggle()}>
                    {list(anchor)}
                </Drawer>
            </React.Fragment>
        </div>
    );
}


const mapStateToProps = (state) => {
    //console.log("----------boardmanager mapstatetoprops");
    //console.log(state)
    const getCurrentData = function (data) {
        //console.log("getData")
        //console.log(data)
        if (data.children.length == 0) return null;
        let id = data.selected_id;
        const length = data.children.length;
        if (id == 0) {
            for (let z = 0; z < length; z++) {
                if (z === data.selected_index) {
                    return data.children[z];
                }
            }
        } else {
            for (let z = 0; z < length; z++) {
                if (data.children[z].id === id) {
                    return data.children[z];
                }
            }
        }
    }
    return {
        state:state,

        currentData: getCurrentData(state.tree.data_root[0]),
    };
}


export default connect(
    mapStateToProps,
)(Temp)



