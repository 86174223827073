import React, { Component } from 'react';
import { connect } from 'react-redux'

import BoardManager from './BoardManager';
import LnbWrap from './LnbWrap'
import ToolEditor from './ToolEditor'
import BottomDatePicker from './BottomDatePicker'
import Toast from '~/components/util/Toast'
class Temp extends Component {
    constructor(props) {
        super(props); 
    }
    render() {
        return (
            <div className="">
                <LnbWrap />
                <BoardManager />
                <ToolEditor/>
                {
                    this.props.state.webapp.LOCAL_NOTIFICATION_TIME_SHOW&&
                    <BottomDatePicker/>
                }
                <Toast/>

            </div>

        );
    }

}

const mapStateToProps = (state) => {
    return {state};
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}
export default connect(
    mapStateToProps,
)(Temp)



 

