import React, { useState } from 'react';
import Bi from './Bi';
import './Intro.scss'
function Temp({}) {
  return (
    <div className="intro">
    <div className="Bi-cont">
        <Bi/>
    </div>
        <div className="loading">
            todo.mosh.kr 시작하는 중입니다..
        </div>
    </div>
  );
}
export default Temp;