import { combineReducers } from 'redux'
import TreeReducer from './TreeReducer'
import LoginReducer from './LoginReducer'
import ToolReducer from './ToolReducer'
import ConfigReducer from './ConfigReducer'
import DialogReducer from './DialogReducer'
import WebAppReducer from './WebAppReducer'

export default combineReducers({
    tree:TreeReducer,login:LoginReducer,tool:ToolReducer,config:ConfigReducer, dialog:DialogReducer, webapp:WebAppReducer
})
