export const TREE_ADD = (data) => ({
    type: 'TREE_ADD',
    data
})
export const TREE_SELECTED_CHANGE = (data) => ({
    type: 'TREE_SELECTED_CHANGE',
    data
})

export const TREE_ADD_AT_FIRST = (data) => ({
    type: 'TREE_ADD_AT_FIRST',
    data
})
export const TREE_ADD_AT_LAST = (data,state) => ({
    type: 'TREE_ADD_AT_LAST',
    data,state
})
export const TREE_ADD_AT_SENIOR = (data) => ({
    type: 'TREE_ADD_AT_SENIOR',
    data
})
export const TREE_DELETE = (data) => ({
    type: 'TREE_DELETE',
    data
})
export const TREE_DELETE_CATEGORY= (data) => ({
    type: 'TREE_DELETE_CATEGORY',
    data
})
export const TREE_DELETE_CHILDREN = (data) => ({
    type: 'TREE_DELETE_CHILDREN',
    data
})
export const TREE_EDIT_NODE_POSITION = (data,dir) => ({
    type: "TREE_EDIT_NODE_POSITION",
    data,dir
})
export const TREE_EDIT_NODE_LEFT = ( data) => ({
    type: "TREE_EDIT_NODE_LEFT",
    data
})
export const TREE_EDIT_NODE_RIGHT = ( data) => ({
    type: "TREE_EDIT_NODE_RIGHT",
    data
})
export const TREE_EDIT_NODE_UP = ( data) => ({
    type: "TREE_EDIT_NODE_UP",
    data
})
export const TREE_EDIT_NODE_DOWN = ( data) => ({
    type: "TREE_EDIT_NODE_DOWN",
    data
})
export const TREE_EDIT_ARROW_UP = ( data) => ({
    type: "TREE_EDIT_ARROW_UP",
    data
})
export const TREE_EDIT_ARROW_DOWN = ( data) => ({
    type: "TREE_EDIT_ARROW_DOWN",
    data
})
export const TREE_EDIT_TITLE = (data,value) => ({
    type: 'TREE_EDIT_TITLE',
    data,value
})

export const TREE_EDIT_TITLE_FNC = (dispatch,data,value) => {
    if(window.it_title_timer){
        clearTimeout(window.it_title_timer)
    }
    dispatch(ACTION_TREE.TREE_EDIT_TITLE(data,value))
}



export const TREE_EDIT_TIMER_TITLE = (dispatch,data,value) => {
    // return;
    if(window.it_title_timer){
        clearTimeout(window.it_title_timer)
    }
    const run=()=>{
        console.log("...TREE_EDIT_TIMER_TITLE int")
        dispatch(ACTION_TREE.TREE_EDIT_TITLE(data,value))
    }
    window.it_title_timer = setTimeout(()=>{
        run();
    }, 200);
}

export const TREE_EDIT_DETAIL = (data) => ({
    type: 'TREE_EDIT_DETAIL',
    data
})
export const TREE_EDIT_DETAIL_CLOSE = (data) => ({
    type: 'TREE_EDIT_DETAIL_CLOSE',
    data
})
export const TREE_EDIT_DETAIL_SAVE = (props) => ({
    type: 'TREE_EDIT_DETAIL_SAVE',
    props
})
export const TREE_INIT = (data) => ({
    type: 'TREE_INIT',
    data
})
export const TREE_DONE = (data,state) => ({
    type: 'TREE_DONE',
    data,state
})
export const TREE_SELECT = (data) => ({
    type: 'TREE_SELECT',
    data
})
export const TREE_HISTORY_UNDO = (data) => ({
    type: 'TREE_HISTORY_UNDO',
})
export const TREE_HISTORY_REDO = (data) => ({
    type: 'TREE_HISTORY_REDO',
})
export const TREE_NODE_OPEN_CLOSE = (data,node,id,open) => ({
    type: 'TREE_NODE_OPEN_CLOSE',data,node,id,open
})
export const TREE_DATA_SAVE = () => ({
    type: 'TREE_DATA_SAVE'
})
export const TREE_DATA_SAVE_SUCCESS = () => ({
    type: 'TREE_DATA_SAVE_SUCCESS'
})
export const TREE_DATA_SAVE_FAIL = () => ({
    type: 'TREE_DATA_SAVE_FAIL'
})
export const TREE_ADD_ENTER = (data,state) => ({
    type: 'TREE_ADD_ENTER',
    data,state
})
export const TREE_ADD_PLUS = (data,state) => ({
    type: 'TREE_ADD_PLUS',
    data,state
})
export const TREE_AUTO_ARRANGE = (data,state) => ({
    type: 'TREE_AUTO_ARRANGE',
    data,state
})
export const TREE_TEMP_OPEN_CLOSE = (data,open) => ({
    type: 'TREE_TEMP_OPEN_CLOSE',
    data,open
})


export const ACTION_TREE={
    TYPE:{
        TREE_ADD:'TREE_ADD',
        TREE_SELECTED_CHANGE:'TREE_SELECTED_CHANGE',
        TREE_ADD_AT_FIRST:'TREE_ADD_AT_FIRST',
        TREE_ADD_AT_LAST:'TREE_ADD_AT_LAST',
        TREE_ADD_AT_SENIOR:'TREE_ADD_AT_SENIOR',
        TREE_DELETE:'TREE_DELETE',
        TREE_DELETE_CATEGORY:'TREE_DELETE_CATEGORY',
        TREE_DELETE_CHILDREN:'TREE_DELETE_CHILDREN',
        TREE_EDIT_NODE_POSITION:'TREE_EDIT_NODE_POSITION',
        TREE_EDIT_NODE_LEFT:'TREE_EDIT_NODE_LEFT',
        TREE_EDIT_NODE_RIGHT:'TREE_EDIT_NODE_RIGHT',
        TREE_EDIT_NODE_UP:'TREE_EDIT_NODE_UP',
        TREE_EDIT_NODE_DOWN:'TREE_EDIT_NODE_DOWN',
        TREE_EDIT_ARROW_UP:'TREE_EDIT_ARROW_UP',
        TREE_EDIT_ARROW_DOWN:'TREE_EDIT_ARROW_DOWN',
        TREE_EDIT_TITLE:'TREE_EDIT_TITLE',
        TREE_EDIT_TITLE_FNC:'TREE_EDIT_TITLE_FNC',
        TREE_EDIT_TIMER_TITLE:'TREE_EDIT_TIMER_TITLE',
        TREE_EDIT_DETAIL:'TREE_EDIT_DETAIL',
        TREE_EDIT_DETAIL_CLOSE:'TREE_EDIT_DETAIL_CLOSE',
        TREE_EDIT_DETAIL_SAVE:'TREE_EDIT_DETAIL_SAVE',
        TREE_INIT:'TREE_INIT',
        TREE_DONE:'TREE_DONE',
        TREE_SELECT:'TREE_SELECT',
        TREE_HISTORY_UNDO:'TREE_HISTORY_UNDO',
        TREE_HISTORY_REDO:'TREE_HISTORY_REDO',
        TREE_NODE_OPEN_CLOSE:'TREE_NODE_OPEN_CLOSE',
        TREE_DATA_SAVE:'TREE_DATA_SAVE',
        TREE_DATA_SAVE_SUCCESS:'TREE_DATA_SAVE_SUCCESS',
        TREE_DATA_SAVE_FAIL:'TREE_DATA_SAVE_FAIL',
        TREE_ADD_ENTER:'TREE_ADD_ENTER',
        TREE_ADD_PLUS:'TREE_ADD_PLUS',
        TREE_AUTO_ARRANGE:'TREE_AUTO_ARRANGE',
        TREE_TEMP_OPEN_CLOSE:'TREE_TEMP_OPEN_CLOSE'

        
    },
    TREE_ADD : TREE_ADD,
    TREE_SELECTED_CHANGE : TREE_SELECTED_CHANGE,
    TREE_ADD_AT_FIRST : TREE_ADD_AT_FIRST,
    TREE_ADD_AT_LAST : TREE_ADD_AT_LAST,
    TREE_ADD_AT_SENIOR:TREE_ADD_AT_SENIOR,
    TREE_DELETE : TREE_DELETE,
    TREE_DELETE_CATEGORY : TREE_DELETE_CATEGORY,
    TREE_DELETE_CHILDREN : TREE_DELETE_CHILDREN,
    TREE_EDIT_NODE_POSITION : TREE_EDIT_NODE_POSITION,
    TREE_EDIT_NODE_LEFT : TREE_EDIT_NODE_LEFT,
    TREE_EDIT_NODE_RIGHT : TREE_EDIT_NODE_RIGHT,
    TREE_EDIT_NODE_UP : TREE_EDIT_NODE_UP,
    TREE_EDIT_NODE_DOWN : TREE_EDIT_NODE_DOWN,
    TREE_EDIT_ARROW_DOWN : TREE_EDIT_ARROW_DOWN,
    TREE_EDIT_ARROW_UP : TREE_EDIT_ARROW_UP,
    TREE_EDIT_TITLE : TREE_EDIT_TITLE,
    TREE_EDIT_TITLE_FNC : TREE_EDIT_TITLE_FNC,
    TREE_EDIT_TIMER_TITLE : TREE_EDIT_TIMER_TITLE,
    TREE_EDIT_DETAIL : TREE_EDIT_DETAIL,
    TREE_EDIT_DETAIL_CLOSE : TREE_EDIT_DETAIL_CLOSE,
    TREE_EDIT_DETAIL_SAVE : TREE_EDIT_DETAIL_SAVE,
    TREE_INIT : TREE_INIT,
    TREE_DONE : TREE_DONE,
    TREE_SELECT:TREE_SELECT,
    TREE_HISTORY_UNDO:TREE_HISTORY_UNDO,
    TREE_HISTORY_REDO:TREE_HISTORY_REDO,
    TREE_NODE_OPEN_CLOSE:TREE_NODE_OPEN_CLOSE,
    TREE_DATA_SAVE:TREE_DATA_SAVE,
    TREE_DATA_SAVE_SUCCESS:TREE_DATA_SAVE_SUCCESS,
    TREE_DATA_SAVE_FAIL:TREE_DATA_SAVE_FAIL,
    TREE_ADD_ENTER:TREE_ADD_ENTER,
    TREE_ADD_PLUS:TREE_ADD_PLUS,
    TREE_AUTO_ARRANGE:TREE_AUTO_ARRANGE,
    TREE_TEMP_OPEN_CLOSE:TREE_TEMP_OPEN_CLOSE
}