import {ACTION_TOOL} from '../actions/ActionTool'

let initialState = {
    show_tool:true
}
let cloneState = function (state) {
    let obj = Object.assign({}, state)
    return obj;
}

const reducer = (state = initialState, action) => {

    //console.log("reducer tool reducer:"+action.type)
    //console.log(state);
    let clone = cloneState(state); 
    //console.log(action)
    switch (action.type) {
        case ACTION_TOOL.TYPE.TOOL_SHOW:
            clone.show_tool = action.bool;
            return clone;
        default:
            return clone;
    }
}

export default reducer
