import React, { useState } from 'react';
import './PrivacyHeader.scss';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import {Link, useHistory} from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

function Temp({title,back}) {
  console.log("settingheader")
  let history = useHistory();
  console.log(history);

  let clickBack=function(){
    console.log("goBack")
    history.goBack();
  }
  return (
    <div className="setting-header-wrap privacy_header">
      <div className="setting-header">
        <div className="setting-title">{title}</div>
        {back&&
          <div className="setting-back">
              <IconButton size="small" onClick={()=>clickBack()} ><KeyboardBackspaceIcon fontSize="small" /></IconButton>
            </div>
        }
      </div>
      <Divider />

    </div>
  );
}
export default Temp;