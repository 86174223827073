import Skin0VO from './skin/Skin0VO'
class Temp {
    constructor({auto_child_complete=true, done_auto_arrange=1,  dir_enter = 1, dir_plus = 1, sound = true, skin = 0, skin0VO = new Skin0VO({}) }) {
        this.dir_enter = dir_enter;
        this.dir_plus = dir_plus;
        this.sound = sound;
        this.skin = skin
        this.done_auto_arrange = done_auto_arrange;
        this.auto_child_complete=auto_child_complete;
        this.skin0VO = new Skin0VO(skin0VO);
    }
    copy({ auto_child_complete=true, done_auto_arrange=1,dir_enter = 1, dir_plus = 1, sound = true, skin = 0, skin0VO = new Skin0VO({}) }) {
        this.dir_enter = dir_enter;
        this.dir_plus = dir_plus;
        this.sound = sound;
        this.skin = skin 
        this.done_auto_arrange = done_auto_arrange;
        this.auto_child_complete=auto_child_complete;
        this.skin0VO = new Skin0VO(skin0VO);
    }
    clone() { 
        return new Temp(this)
    }
    equal({auto_child_complete=true,  done_auto_arrange=1,dir_enter = 1, dir_plus = 1, sound = true, skin = 0, skin0VO = new Skin0VO({}) }) {
        if (this.auto_child_complete===auto_child_complete&&this.done_auto_arrange ===done_auto_arrange && this.dir_plus === dir_plus && this.sound === sound && this.skin === skin && this.dir_enter === dir_enter) {
            return this.skin0VO.equal(skin0VO)
        } 
        return false;
    }
}
export default Temp;
