import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SecurityIcon from '@material-ui/icons/Security';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import NotesIcon from '@material-ui/icons/Notes';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import BuildIcon from '@material-ui/icons/Build';
import SettingHeader from './SettingHeader';

import { Link } from 'react-router-dom';
// import firebase from 'firebase/app';

import './Setting.scss';
import { connect } from 'react-redux'
import { ACTION_CONFIG } from '~/actions/ActionConfig'
import { getAuth } from 'firebase/auth';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,

  },
  list_radio: {
    // paddingTop:"13px",
    // paddingBottom:"13px"
    // height:"32px"
    paddingTop: "5px",
    paddingBottom: "5px"

  }
}));


function Temp({ dispatch, state_login, state_config, destroy }) {
  const classes = useStyles();
  const [radio_dir_enter, set_radio_dir_enter] = React.useState(state_config.config.dir_enter);
  const [radio_dir_plus, set_radio_dir_plus] = React.useState(state_config.config.dir_plus);
  const [switch_mute, set_switch_mute] = React.useState(Boolean(state_config.config.sound));
  const [switch_auto_arrange, set_switch_auto_arrange] = React.useState(Boolean(state_config.config.done_auto_arrange));
  const [switch_auto_child_complete, set_switch_auto_child_complete] = React.useState(Boolean(state_config.config.auto_child_complete));

  const changeRadio = (e, type, func) => {
    func(Number(e.target.value));
    if (type === "enter") {
      changeData(Number(e.target.value), radio_dir_plus, switch_mute)
    } else {
      changeData(radio_dir_enter, Number(e.target.value), switch_mute)
    }
  }
  const changeData = (radio_dir_enter, radio_dir_plus, switch_mute, switch_auto_arrange, switch_auto_child_complete) => {
    state_config.config.dir_enter = radio_dir_enter
    state_config.config.dir_plus = radio_dir_plus
    state_config.config.sound = switch_mute
    state_config.config.done_auto_arrange = switch_auto_arrange
    state_config.config.auto_child_complete = switch_auto_child_complete;
    dispatch(ACTION_CONFIG.CONFIG_CHANGE(state_config))
  }
  const changeMute = (e) => {
    set_switch_mute(e.target.checked);
    changeData(radio_dir_enter, radio_dir_plus, e.target.checked, switch_auto_arrange, switch_auto_child_complete)
  };
  const changeAutoArrange = (e) => {
    set_switch_auto_arrange(e.target.checked);
    changeData(radio_dir_enter, radio_dir_plus, switch_mute, e.target.checked, switch_auto_child_complete)
  };
  const changeAutoChildComplete = (e) => {
    set_switch_auto_child_complete(e.target.checked);
    changeData(radio_dir_enter, radio_dir_plus, switch_mute, switch_auto_arrange, e.target.checked)
  };


  const androidClick=()=>{
    window.Android.showToast("abc")
  }
  const iosClick=()=>{
    // window.Android.showToast("abc")
    if(window.webkit){
      window.webkit.messageHandlers.callbackHandler.postMessage("yhcho msg body");

    }
	
  }
  let cls_logout = ""
  if (state_login.user.uid == null) {
    cls_logout = "deActive"
  }
  return (
    <div className={classes.root}>
      <SettingHeader title="설정" back={false} />
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button component={Link} to="/setting/account" >
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="계정 관리" />
        </ListItem>
        {/* <ListItem button component={Link} to="/setting/login" >
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="로그인" />
        </ListItem>
        <ListItem button onClick={() => logout()} className={cls_logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="로그아웃" />
        </ListItem> */}
      </List>
      <Divider />
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button className={classes.list_radio + " setting-radio-wrap"}>
          <ListItemIcon>
            <VolumeUpIcon />
          </ListItemIcon>
          <ListItemText primary="소리" />
          <Switch
            checked={switch_mute}
            onChange={(e) => changeMute(e)}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ListItem>
        <ListItem button className={classes.list_radio + " setting-radio-wrap"}>
          <ListItemIcon>
            <AutorenewIcon />
          </ListItemIcon>
          <ListItemText primary="완료시 하단 정렬" />
          <Switch
            checked={switch_auto_arrange}
            onChange={(e) => changeAutoArrange(e)}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ListItem>
        <ListItem button className={classes.list_radio + " setting-radio-wrap"}>
          <ListItemIcon>
            <DoneAllIcon />
          </ListItemIcon>
          <ListItemText primary="완료시 포함된 todo 모두 완료" />
          <Switch
            checked={switch_auto_child_complete}
            onChange={(e) => changeAutoChildComplete(e)}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ListItem>
        {/* <ListItem button className={classes.list_radio + " setting-radio-wrap"}>
          <ListItemIcon>
            <KeyboardReturnIcon />
          </ListItemIcon>
          <ListItemText primary="생성 방향" />
          <RadioGroup row aria-label="position" name="position" value={radio_dir_enter} onChange={(e)=>changeRadio(e, "enter",set_radio_dir_enter)}>
            <FormControlLabel size="small" value={0} control={<Radio color="primary" />} label={<ArrowUpwardIcon/>} />
            <FormControlLabel size="small" value={1} control={<Radio color="primary" />} label={<ArrowDownwardIcon/>}  />
          </RadioGroup>
        </ListItem>
        <ListItem button className={classes.list_radio + " setting-radio-wrap"}>
          <ListItemIcon> 
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="생성 방향" />
          <RadioGroup row aria-label="position" name="position" value={radio_dir_plus} onChange={(e)=>changeRadio(e, "plus",set_radio_dir_plus)}>
            <FormControlLabel value={0} control={<Radio color="primary" />} label={<ArrowUpwardIcon/>} />
            <FormControlLabel value={1} control={<Radio color="primary" />} label={<ArrowDownwardIcon/>} />
          </RadioGroup>
        </ListItem> */}
        <ListItem button component={Link} to="/setting/skin">
          <ListItemIcon>
            <NotesIcon />
          </ListItemIcon>
          <ListItemText primary="스킨 설정" />
        </ListItem>
      </List>
      <Divider />
      <ListItem button component={Link} to="/setting/build_info">
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary="버전 정보" />
        </ListItem>
      {/* <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button>
          <ListItemIcon>
            <ThumbUpAltIcon />
          </ListItemIcon>
          <ListItemText primary="추천" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <FavoriteIcon />
          </ListItemIcon>
          <ListItemText primary="제안" />
        </ListItem>

      </List>

      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button>
          <ListItemIcon>
            <AnnouncementIcon />
          </ListItemIcon>
          <ListItemText primary="공지" />
        </ListItem>
      </List> */}

      {/*<List component="nav" aria-label="secondary mailbox folders">*/}
      {/*  <ListItem button onClick={() => androidClick()}>*/}
      {/*    <ListItemText primary="android test" />*/}
      {/*  </ListItem>*/}
      {/*</List> */}
           {/* <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button onClick={() => iosClick()}>
          <ListItemText primary="ios test" />
        </ListItem>
      </List> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state)
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,
    state_config: state.config
  };
}

export default connect(
  mapStateToProps,
)(Temp)


