import React, { Component } from 'react';
import Setting from './Setting';
import { connect } from 'react-redux'
import { ACTION_CONFIG } from '~/actions/ActionConfig';

class Temp extends Component {
    constructor(props) {
        super(props);
    }
    componentWillUnmount() {
        this.props.dispatch(ACTION_CONFIG.CONFIG_SAVE(this.props.state_login.user.uid))
    }
    render() {
        return (
            <div>
                {
                    this.props.state_config.loaded &&
                    <Setting />
                }
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    //console.log(state)
    return {
        state_tree: state.tree,
        state_login: state.login,
        state_tool: state.tool,
        state_config: state.config
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch: dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Temp)


