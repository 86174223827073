import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { connect } from 'react-redux'
import {ACTION_WEBAPP} from '~/actions/ActionWebApp'
import {ACTION_DIALOG} from '~/actions/ActionDialog'

import './BottomDatePicker.scss';

import 'date-fns';

import addSeconds from 'date-fns/addSeconds'
import addMinutes from 'date-fns/addMinutes'
import addHours from 'date-fns/addHours';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';


import Grid from '@material-ui/core/Grid'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider, 
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

function Temp({state,dispatch}) {
    const classes = useStyles();


    const [open, setOpen]= React.useState(state.webapp.LOCAL_NOTIFICATION_TIME_SHOW_ANI);

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };

    const openDrawer=(anchor, open)=>{
        if(!open){
            // setOpen(false);
            dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_TIME_SHOW_ANI(null,false))
            setTimeout(()=>{
                dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_TIME_SHOW(null,false))
            },1000)
        }
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        openDrawer(anchor, open)
        
    };
    console.log(".......time")
    console.log(state.tree.selected_tree_data) 
    let data = state.tree.selected_tree_data;
    let time;


    let currentTime= new Date();
    let deleteBoolean=false;
    if(data.local_push_noti_time){
        time = new Date(data.local_push_noti_time)
        console.log("............time")
        console.log(currentTime)
        console.log(time)
        if(currentTime<time){
            deleteBoolean = true;
        }else{
            time = new Date();
        }
    }else{
        time = new Date();
        // let isoString = time.toISOString();
        // let dateFns =new  DateFnsUtils();
        // let resultDate =  dateFns.date(isoString);
        // let tt  = addHours(resultDate, 2)
    }
    
    const [selectedDate, setSelectedDate] = React.useState(time);

    if(state.webapp.LOCAL_NOTIFICATION_TIME_SHOW_ANI){
        setTimeout(()=>{
            setOpen(true);
        },1)
    }else{
        setTimeout(()=>{
            setOpen(false);
        },1)
    }
    const changeTime=(month, week, day, hour, min, sec)=>{
        let isoString = selectedDate.toISOString();
        let dateFns =new  DateFnsUtils();
        let resultDate =  dateFns.date(isoString);
        let t = addMonths(addWeeks(addDays(addHours(addMinutes(addSeconds(resultDate,sec), min),hour),day),week),month)
        setSelectedDate(new Date(t))

    }
    const resetTime=()=>{
        setSelectedDate(new Date());
    }

    const alramAdd=()=>{
        state.tree.selected_tree_data.local_push_noti_time = selectedDate.toISOString();
        let noti_id = Math.floor(Math.random()*100000000) 
        dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_ADD(state.tree.selected_tree_data,noti_id));
        openDrawer("bottom",false);
        dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_CHANGE(state.tree.selected_tree_data,noti_id));

        // window.alarm_save_success();//test code
    }
    const alramRemove=()=>{
        dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_REMOVE(state.tree.selected_tree_data))
        resetTime();
        openDrawer("bottom",false);
        // dispatch(ACTION_DIALOG.TOAST_SHOW("알람 삭제 완료!"));
        ACTION_DIALOG.TOAST_SHOW(dispatch,"알람 삭제 완료!",true,"alarmoff");
    }
    window.alarm_save_fail=()=>{
        // alert("fail")
        ACTION_DIALOG.TOAST_SHOW(dispatch,"알람 등록 실패! 알림 허용 세팅해주세요!",true,"alarm");
    }
    window.alarm_save_success=()=>{
        // alert("success")
        state.tree.selected_tree_data.local_push_noti_time = selectedDate.toISOString();
        dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_ADD_SUCCESS(state.tree.selected_tree_data));
        ACTION_DIALOG.TOAST_SHOW(dispatch,"알람 설정 완료!",true,"alarm");
    }
    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            }) + " date-picker-wrap"}
            role="presentation"
        >
            <List>
                <ListItem>
                    <div className="alram-header">
                        {!deleteBoolean&&<span className="alram-left-empty"></span>}
                        {deleteBoolean&&
                            <span className="alram-left">
                                <Button color="secondary" onClick={()=>alramRemove()}>삭제</Button> 
                            </span>
                        }
                        <span className="alram-center">알림</span>
                        <span className="alram-right">
                            <Button color="primary" onClick={()=>alramAdd()}>저장</Button> 
                        </span>
                    </div> 
                </ListItem>
                <Divider />
                <ListItem>
                    <div className="hor-slider-wrap">
                        <div className="hor-slider">
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,0,10)}>10초</Button> 
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,0,30)}>30초</Button> 
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,1,0)}>1분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,2,0)}>2분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,3,0)}>3분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,4,0)}>4분</Button>
                        </div> 
                        <div className="hor-slider">
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,5,0)}>5분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,6,0)}>6분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,7,0)}>7분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,8,0)}>8분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,9,0)}>9분</Button>
                        </div> 
                        <div className="hor-slider">
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,10,0)}>10분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,15,0)}>15분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,20,0)}>20분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,25,0)}>25분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,30,0)}>30분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,35,0)}>35분</Button> 
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,40,0)}>40분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,45,0)}>45분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,50,0)}>50분</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,0,55,0)}>55분</Button>
                        </div> 
                    
                        <div className="hor-slider">
                            <Button size="small" onClick={()=>changeTime(0,0,0,1,0,0)}>1시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,2,0,0)}>2시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,3,0,0)}>3시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,4,0,0)}>4시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,5,0,0)}>5시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,6,0,0)}>6시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,7,0,0)}>7시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,8,0,0)}>8시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,9,0,0)}>9시</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,0,10,0,0)}>10시</Button>
                        </div>
                        <div className="hor-slider">
                            <Button size="small" onClick={()=>changeTime(0,0,1,0,0,0)}>내일</Button>
                            <Button size="small" onClick={()=>changeTime(0,0,2,0,0,0)}>모레</Button>
                            <Button size="small" onClick={()=>changeTime(0,1,0,0,0,0)}>일주</Button>
                            <Button size="small" onClick={()=>changeTime(1,0,0,0,0,0)}>한달</Button>
                            <Button size="small" onClick={()=>resetTime()}>초기화</Button>
                        </div>
                    </div>
                    
                </ListItem>
               
                <ListItem >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="날짜"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        /> 
                        <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        format="hh:mm:ss aa"

                        label="시간"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                </ListItem>
            </List>
        </div>
    );
   

    let anchor = "bottom"
    return (
        <div className="bottom-date-picker">
            <React.Fragment key={anchor}>
                {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
                <SwipeableDrawer
                    anchor={anchor}
                    open={open}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                >
                    {list(anchor)}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {state};
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}
export default connect(
    mapStateToProps,
)(Temp)



 