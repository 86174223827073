import React, {Component} from 'react';
import './ToolBottom.scss';
import IconButton from '@material-ui/core/IconButton';
import { ACTION_TREE} from '~/actions/ActionTree'
import { connect } from 'react-redux'
 

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
function Temp({dispatch,state_tree,state}) {

  return (
    <div className="tool-bottom">
        <div className="tool-bottom-wrap">
            <IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_ADD_AT_LAST(state_tree.selected_page,state))}>
                <AddCircleOutlineIcon fontSize="small" />
            </IconButton>
        </div>
    </div>
  );
}
const mapStateToProps = (state) => {
    console.log("----------mapStateToProps");
    return {
        state_tree: state.tree,
        state_login: state.login,
        state_tool: state.tool,
        state: state,

  
        // datas:state.TreeReducer.data_root,
        // propExpanded:state.TreeReducer.propExpanded,
        // show_edit_dialog:state.TreeReducer.show_edit_dialog,
        // show_tool:state.ToolReducer.show_tool
    };
  }
  
  const mapDispatchToProps = (dispatch, ownProps) => ({
    // treeNodeOnChange: () => dispatch(setVisibilityFilter(ownProps.filter))
  })
  
  
  export default connect(
    mapStateToProps
  )(Temp)
  