export const TOOL_SHOW = (bool) => ({
    type: 'TOOL_SHOW',
    bool
})

export const ACTION_TOOL={
    TYPE:{
        TOOL_SHOW:'TOOL_SHOW'
    },
    TOOL_SHOW :TOOL_SHOW
}