import React, { Component } from 'react';
import RouterWrap from './components/layout/RouterWrap'
import rootReducer from './reducers/index'
import { Provider } from 'react-redux'
import { createStore,applyMiddleware,compose } from 'redux'
import thunk from 'redux-thunk';
import FirebaseWrap from './components/auth/FirebaseWrap'
import { Route } from "react-router-dom";
import Privacy from './components/view/terms/Privacy'
import Terms from './components/view/terms/Terms'
import ConfirmDialog from './components/view/common/ConfirmDialog'
// import './Contents.scss';


class Temp extends Component {
    constructor(props) {
        super(props);
        // this.history = createBrowserHistory();
        // this.store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())


        const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        this.store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));


//         const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunkMiddleware)
// const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer)

    }


    render() {
        return (
            <Provider store={this.store}>
                <Route exact path="/*" component={FirebaseWrap} ></Route>

                {/* <FirebaseWrap /> */}
                <RouterWrap />
                <Route exact path="/setting/privacy" component={Privacy} ></Route>
                <Route exact path="/setting/terms" component={Terms} ></Route>
                <ConfirmDialog/>
                {/*<input type="text" onClick={this.clickIf()} />*/}
            </Provider>  
        );
    }
}

export default Temp;
 