import React from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

import ListIcon from '@material-ui/icons/List';
import InputBase from '@material-ui/core/InputBase';
import { ACTION_TREE } from '~/actions/ActionTree'


import "./ToolHeader.scss";
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    width: "210px"
  },
  margin: {
    // margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Temp({ dispatch, state_tree, state_tool, onMenuToggle, currentTitle, state_login, headerReset }) {
  const classes = useStyles();
  const [titleText, setTitleText] = React.useState(currentTitle);

  let textfieldHandleChange = function (e, data) {
    dispatch(ACTION_TREE.TREE_EDIT_TITLE(data, e.target.value))
    setTitleText(e.target.value)
  }
  console.log(":currentTitle:" + currentTitle)



  const deleteClick = () => {
    dispatch(ACTION_TREE.TREE_DELETE_CATEGORY(state_tree.selected_page))
    headerReset();
  }
  return (
    <div className="toolheader-cont">
        <span className="icon-category-wrap">
        <span className="icon-category">
          <IconButton size="small" aria-label="delete" className={classes.margin} onClick={() => onMenuToggle()}>
            <ListIcon fontSize="small" />
          </IconButton>
        </span>
</span>
 

      <span className="tf-wrap">
        <InputBase
          className={classes.title}
          defaultValue={titleText}
          autoFocus={currentTitle == ""}
          //   defaultValue={"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"} 
          placeholder="todo"
          onBlur={(e) => textfieldHandleChange(e, state_tree.data_root[0].selected_data)}
          inputProps={{ 'aria-label': 'naked' }}
        />
      </span>
      <span className="icon-cont-bg">

      </span>

      <span className="icon-cont header_right">
        <span className="icon-wrap item rt">
          <span className="icon-ct">
            <IconButton size="small" aria-label="delete" className={classes.margin} onClick={() => deleteClick()}>
              <DeleteSweepIcon fontSize="small" />
            </IconButton>
          </span>
        </span>
          <span className="icon-wrap item rt">
          <span className="icon-ct">
            <IconButton size="small" aria-label="delete" className={classes.margin} component={Link}  to="/setting" >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </span>
        </span>


      </span>

    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log("----------mapStateToProps");
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,

    // datas:state.TreeReducer.data_root,
    // propExpanded:state.TreeReducer.propExpanded,
    // show_edit_dialog:state.TreeReducer.show_edit_dialog,
    // show_tool:state.ToolReducer.show_tool
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  // treeNodeOnChange: () => dispatch(setVisibilityFilter(ownProps.filter))
})


export default connect(
  mapStateToProps
)(Temp)
