import React, { useState } from 'react';
import './Bi.scss';
function Temp({}) {
  return (
    <div className="bi">
        <div className="title">
            <div className="icon-cont ab bicheck">
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path></svg>
            </div>
            <div className="tx_cont pr">
                <div className="bg"></div>
                <div className="tx">todo.mosh.kr</div>
            </div>
        </div>
    </div>
  );
}
export default Temp;