import React, { Component } from 'react';
import { connect } from 'react-redux'

import TreeUI from './tree/TreeUI';
import BoardEditDialog from './container/BoardEditDialog';
import './BoardManager.scss'
import ToolBottom from './ToolBottom'

class BoardManager extends Component {
    constructor(props) {
        super(props);
    }
    clickAdd(){
        let data = this.props.state_tree.data_root[0]
        const selected_index = data.selected_index;
        let selectData = this.getData(data)//data.children[selected_index];
        this.props.onAdd(selectData);
    }
     getData(data){
        if(data.children.length==0)return null;
        let id = data.selected_id;
        const length =data.children.length;
        if(id==0){
            for(let z=0; z<length; z++){
                if(z===data.selected_index){
                    return data.children[z];
                }
            }
        }else{
            for(let z=0; z<length; z++){
                if(data.children[z].id===id){
                    return data.children[z];
                }
            }
        }
    }


    render() {

        return (
            <div>
                <div>
                    <div className="tree-ui-cont">
                        <TreeUI />
                    </div>
                    <ToolBottom/>

                    <div className="edit-dialog">
                        {this.props.state_tree.selected_data && this.props.state_tree.show_edit_dialog &&
                            // <BoardEditDialog vo={this.props.state_tree.selected_data} onDialogCancel={() => this.props.onDialogCancel()} onDialogSave={(datas) => this.props.onDialogSave(datas)} />
                            <BoardEditDialog />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state_tree: state.tree,
        state_login: state.login,
        state_tool: state.tool,
    };
}

export default connect(
    mapStateToProps,
)(BoardManager)


