import axios from 'axios';

export const LOGIN_SUCCESS = (user) => ({
    type: 'LOGIN_SUCCESS',
    user
})

export const USER_DATA_INIT = (data) => ({
    type: 'USER_DATA_INIT',
    data
})

export const USER_DATA_REQUEST = (dispatch,uid) => {
    let count = 0;
    const request=(uid)=>{
        let THIS = this;
        axios.get('/api/todo/userdata', {
            params: {
                uid: uid
            }
        })
        .then(function (response) {
            dispatch(USER_DATA_INIT(response.data))
        })
        .catch(function (error) {
            count++;
            if(count<6){
                request(uid)
            }
        });
    }
    request(uid);
}


export const ACTION_LOGIN={
    TYPE:{
        LOGIN_SUCCESS:'LOGIN_SUCCESS',
        USER_DATA_REQUEST:'USER_DATA_REQUEST',
        USER_DATA_INIT:'USER_DATA_INIT',
    },
    LOGIN_SUCCESS : LOGIN_SUCCESS,
    USER_DATA_REQUEST:USER_DATA_REQUEST,
    USER_DATA_INIT:USER_DATA_INIT,
}