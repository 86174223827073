export const SHARE_TEXT = (str) => ({
    type: 'SHARE_TEXT',
    str
})
export const SHARE_IMAGE = (bitmap) => ({
    type: 'SHARE_IMAGE',
    bitmap
})
export const LOCAL_NOTIFICATION_TIME_SHOW = (data,bool) => ({
    type: 'LOCAL_NOTIFICATION_TIME_SHOW',
    data,bool
})
export const LOCAL_NOTIFICATION_TIME_SHOW_ANI = (data,bool) => ({
    type: 'LOCAL_NOTIFICATION_TIME_SHOW_ANI',
    data,bool
})
export const LOCAL_NOTIFICATION_ADD = (data, noti_id) => ({
    type: 'LOCAL_NOTIFICATION_ADD',
    data, noti_id
})
export const LOCAL_NOTIFICATION_CHANGE = (data, noti_id) => ({
    type: 'LOCAL_NOTIFICATION_CHANGE',
    data, noti_id
})
export const LOCAL_NOTIFICATION_ADD_SUCCESS = (data) => ({
    type: 'LOCAL_NOTIFICATION_ADD_SUCCESS',
    data
})
export const LOCAL_NOTIFICATION_REMOVE = (data) => ({
    type: 'LOCAL_NOTIFICATION_REMOVE',
    data
})
export const LOCAL_NOTIFICATION_REMOVE_SUCCESS = (data) => ({
    type: 'LOCAL_NOTIFICATION_REMOVE_SUCCESS',
    data
})

export const ACTION_WEBAPP={
    TYPE:{
        SHARE_TEXT:'SHARE_TEXT',
        SHARE_IMAGE:'SHARE_IMAGE',
        LOCAL_NOTIFICATION_TIME_SHOW:'LOCAL_NOTIFICATION_TIME_SHOW',
        LOCAL_NOTIFICATION_TIME_SHOW_ANI:'LOCAL_NOTIFICATION_TIME_SHOW_ANI',

        LOCAL_NOTIFICATION_ADD:'LOCAL_NOTIFICATION_ADD',
        LOCAL_NOTIFICATION_CHANGE:'LOCAL_NOTIFICATION_CHANGE',
        LOCAL_NOTIFICATION_ADD_SUCCESS:'LOCAL_NOTIFICATION_ADD_SUCCESS',
        LOCAL_NOTIFICATION_REMOVE:'LOCAL_NOTIFICATION_REMOVE',
        LOCAL_NOTIFICATION_REMOVE_SUCCESS:'LOCAL_NOTIFICATION_REMOVE_SUCCESS'
    },
    SHARE_TEXT :SHARE_TEXT,
    SHARE_IMAGE :SHARE_IMAGE,
    LOCAL_NOTIFICATION_TIME_SHOW:LOCAL_NOTIFICATION_TIME_SHOW,
    LOCAL_NOTIFICATION_TIME_SHOW_ANI:LOCAL_NOTIFICATION_TIME_SHOW_ANI,
    
    LOCAL_NOTIFICATION_ADD:LOCAL_NOTIFICATION_ADD,
    LOCAL_NOTIFICATION_CHANGE:LOCAL_NOTIFICATION_CHANGE,
    LOCAL_NOTIFICATION_ADD_SUCCESS:LOCAL_NOTIFICATION_ADD_SUCCESS,
    LOCAL_NOTIFICATION_REMOVE:LOCAL_NOTIFICATION_REMOVE,
    LOCAL_NOTIFICATION_REMOVE_SUCCESS:LOCAL_NOTIFICATION_REMOVE_SUCCESS
}