import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import SecurityIcon from '@material-ui/icons/Security';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { Link } from 'react-router-dom';
// import firebase from 'firebase/app';
import * as firebase from 'firebase/app';

import './Account.scss';
import { connect } from 'react-redux'
import {ACTION_DIALOG} from'~/actions/ActionDialog'
import axios from 'axios';
import SettingHeader from '../SettingHeader';
import { getAuth } from 'firebase/auth';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,

  },
  list_radio: {
    // paddingTop:"13px",
    // paddingBottom:"13px"
    // height:"32px"
    paddingTop: "5px",
    paddingBottom: "5px"

  }
}));


function Temp({ dispatch,state_login, state_config, destroy }) {
  const classes = useStyles();


  //console.log("setting destroy:",destroy)
  //console.log("setting...")

  let user = localStorage.getItem("user");
  localStorage.removeItem(user);
  localStorage.removeItem("user");
  localStorage.removeItem("firebaseui::rememberedAccounts")
  const logout = () => {
    if (state_login.user.uid == null) {
      //console.log("uid not found")
    }
    console.log('logout.')
    const auth = getAuth();
    auth.signOut().then(function () {
      localStorage.clear();
      sessionStorage.clear();
      console.log('Sign-out successful.')


    }).catch(function (error) {
      console.log(error)
      // An error happened.
    });
  }
  const accountDelete=()=>{
    console.log("accountdelete")
    let count=0;
    const request=(uid)=>{
      let THIS = this;
      axios.get('/api/todo/user/delete', {
          params: {
              uid:uid
          }
      })
      .then(function (response) {
        logout();
        // test
      })
      .catch(function (error) {
        count++;
        if(count<6){
          request(uid)
        }
      });
    }
    request( state_login.user.uid);
  }

  const deleteAccount=()=>{
    dispatch(ACTION_DIALOG.DIALOG_OPEN(["회원탈퇴 진행시 데이터가 영구적으로 삭제됩니다.","탈퇴 하시겠습니까?"], "확인", "취소", accountDelete,null))
  }
  let cls_logout = ""
  if (state_login.user.uid == null) {
    cls_logout = "deActive"
  }

  return (
    <div className={classes.root}>
      <SettingHeader title="계정 관리" back={true}/>
      
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button component={Link} to="/setting/account_info" >
          <ListItemIcon>
            <PersonIcon /> 
          </ListItemIcon>
          <ListItemText primary="계정 정보" />
        </ListItem>
        <ListItem button onClick={() => logout()} className={cls_logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="로그아웃" />
        </ListItem>
      </List>
      <Divider />
      <ListItem button onClick={() => deleteAccount()} className={cls_logout}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="회원탈퇴" />
        </ListItem>

    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state)
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,
    state_config: state.config,
  };
}

export default connect(
  mapStateToProps,
)(Temp)


