import {ACTION_DIALOG } from '../actions/ActionDialog'
let initialState = {
    show:false,
    msg:[],
    msg_ok:"",
    msg_cancle:"",
    callbackSuccess:null,
    callbackFail:null,
    
    toast_open:false,
    toast_text:"완료",
    toast_icon:null
}
let cloneState = function (state) {
    let obj = Object.assign({}, state)
    return obj;
}

const reducer = (state = initialState, action) => {

    // console.log("reducer dialog reducer:"+action.type)
    //console.log(state);
    // console.log(action)
    let clone = cloneState(state); 
    //console.log(action)
    switch (action.type) {
        case ACTION_DIALOG.TYPE.DIALOG_OPEN:
            clone.show=true;
            clone.msg=action.msg
            clone.msg_ok=action.msg_ok
            clone.msg_cancle=action.msg_cancle
            clone.callbackSuccess=action.callbackSuccess;
            clone.callbackFail=action.callbackFail;
            return clone;
        case ACTION_DIALOG.TYPE.DIALOG_CLOSE:
            clone.show=false;
            clone.msg=[]
            clone.msg_ok=""
            clone.msg_cancle=""
            clone.callbackSuccess=null;
            clone.callbackFail=null;
            return clone;
        case ACTION_DIALOG.TYPE.TOAST_OPEN:
            clone.toast_open = action.open
            clone.toast_text= action.text
            clone.toast_icon = action.icon
            return clone;
            break;
        default:
            return clone;
    }
}

export default reducer
