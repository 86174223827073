import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import './ShareToolMenu.scss';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux'
import {ACTION_WEBAPP} from "~/actions/ActionWebApp";
import html2canvas from "html2canvas";
import $ from 'jquery';
import {ACTION_TREE} from "~/actions/ActionTree";

function Temp({state,dispatch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  let isApp=false;
  if(window.Android||window.webkit){
    isApp=true;
  }
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getTabString = function (depth) {
    let str = ""
    for (var z=0; z < depth; z++) {
      str += "   "
    }
    return str;
  }
  const newLine = () => {
    return "\n";
  }
  const getText=function(data, depth){
    let doneStr=""
    if(data.done){
      doneStr="(완료)"
    }
    return getTabString(depth) + data.title+doneStr + newLine()
    
  }
  const getChildString = function (data, depth) {
    let str = ""
    let ary = data.children
    if(depth==0){
        str += getText(data, depth)
        depth+=1;
    }
    for (var z = 0; z < ary.length; z++) {
      str += getText(ary[z], depth);
      if (ary[z].children.length > 0) {
        str += getChildString(ary[z], depth + 1);
      }
    }
    return str;
  }
  const clickClipboard = () => {
    handleClose();
    let str = getTodoText();
    copy(str);
    handleClose();
  }
  const getTodoText=()=>{

    let data;
    if (checked) {
      if(state.tree.selected_tree_data){
        data = searchData(state.tree.data_root[0].children, state.tree.selected_tree_data.id)
      }else{
        data = searchData(state.tree.data_root[0].children, state.tree.selected_page.id)
      }
    } else {
      data = searchData(state.tree.data_root[0].children, state.tree.selected_page.id)
    }
    let str = getChildString(data, 0);
    return str;
  }
  const clickShareText=()=>{
    handleClose();

    let str = getTodoText();
    dispatch(ACTION_WEBAPP.SHARE_TEXT(str))

  }
  const imageSaveReady=(show)=>{
    if(show){
      dispatch(ACTION_TREE.TREE_TEMP_OPEN_CLOSE(state.tree.selected_page, true));
      setTimeout(()=>{
        svgShow(false);
      },50)
     
    }else{
      svgShow(true);
      dispatch(ACTION_TREE.TREE_TEMP_OPEN_CLOSE(state.tree.selected_page, false));
    }
  }
  const clickShareImage=()=>{
    handleClose();

    let str = getTodoText();

    imageSaveReady(true)
    setTimeout(()=>{
      html2canvas(document.getElementById("tree-body")).then(function(canvas) {
        dispatch(ACTION_WEBAPP.SHARE_IMAGE(canvas.toDataURL()));
        imageSaveReady(false)
      });
    },51)
  }
  const clickDownImage=()=>{
    handleClose();

    var download = function(name,bit){
      var link = document.createElement('a');
      link.download = name+'.png';
      link.href = bit
      link.click();
      link.remove();
    }
    let str = getTodoText();

    imageSaveReady(true)

    setTimeout(()=>{
      html2canvas(document.getElementById("tree-body")).then(function(canvas) {
        download("todo",canvas.toDataURL() )
        imageSaveReady(false)

      });
    },50)
  }

  const svgShow=(bool)=>{
    if(bool){
      // $("#tree-body svg").show();
      $("#tree-body .selection").show();
      $("#tree-body svg").removeClass("opacity0");
      $("#tree-body .fake-tf").addClass("off")
      $("#tree-body .input-label-title").removeClass("off")




    }else{
      // $("#tree-body svg").hide();
      $("#tree-body svg").addClass("opacity0");
      $("#tree-body .selection").hide();
      $("#tree-body .fake-tf").removeClass("off")
      $("#tree-body .input-label-title").addClass("off")



    }
  }

  const searchData = function (datas, id) {
    let length = datas.length;
    for (let z = 0; z < length; z++) {
      let item = datas[z];
      if (item.id == id) {
        return item
        break;
      }
      let i = searchData(item.children, id);
      if (i != null) {
        return i;
      }
    }
    return null;
  }

  return (
    <div>
      {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Open Menu
      </Button> */}
      <span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick="" onClick={handleClick}><ShareIcon fontSize="small" /></IconButton></span>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/*
        <FormGroup className="radio-wrap">
          <FormControlLabel
            control={<Switch size="small" checked={checked} onChange={toggleChecked} />}
            label="선택 공유"
          />
        </FormGroup>
        */
        }
        {/*only device */}
        {
          isApp&&
              <div>
                <MenuItem onClick={clickShareText}>텍스트 공유</MenuItem>
                {/* <MenuItem onClick={clickShareImage}>이미지 공유</MenuItem> */}
              </div>
        }
        {
          !isApp&&
          <div>
            <MenuItem onClick={clickClipboard}>클립보드 복사</MenuItem>
            {/* <MenuItem onClick={clickDownImage}>이미지 저장</MenuItem> */}
          </div>
        }

        {/*end */}
        {/*only web*/}

        {/* <MenuItem onClick={handleClose}>download pdf</MenuItem>
        <MenuItem onClick={handleClose}>download image</MenuItem>
        <MenuItem onClick={handleClose}>send to email</MenuItem>
        <MenuItem onClick={handleClose}>share to facebook</MenuItem>
        <MenuItem onClick={handleClose}>share to twitter</MenuItem>
        <MenuItem onClick={handleClose}>share to kakaotalk</MenuItem>
        <MenuItem onClick={handleClose}>share to line</MenuItem>
        <MenuItem onClick={handleClose}>share to band</MenuItem> */}
        {/*  end*/}
      </Menu>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    state:state
  };
}


export default connect(
  mapStateToProps,
)(Temp)



