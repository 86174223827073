import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import './ToolEditor.scss'
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { ACTION_TREE } from '~/actions/ActionTree'
import ShareTool from './ShareTool';
import ShareToolMenu from './ShareToolMenu';
import AutorenewIcon from '@material-ui/icons/Autorenew';


function Temp({ dispatch, data, parentChild, depth, state, state_tree }) {

  const [shareOpen, setShareOpen] = React.useState(false);

  if (parentChild == null) data = null; //삭제 된 상태
  let availLeft = function (data, parentChild) {
    if (data == null) return false;
    if (depth > 2) {
      return true;
    }
    return false;
  }
  let availRight = function (data, parentChild) {
    // 나보다 형이 없을 경우 
    if (data == null) return false;
    let length = parentChild.length
    let bool = false;
    for (let z = 0; z < length; z++) {
      if (parentChild[z].id == data.id) {
        if (z != 0) return true;
      }
    }
    return false;
  }
  let availUp = function (data, parentChild) {
    if (data == null) return false;

    if (parentChild[0].id != data.id) {
      return true;
    } else {
      return false;
    }
  }
  let availDown = function (data, parentChild) {
    if (data == null) return false;

    if (parentChild[parentChild.length - 1].id != data.id) {
      return true;
    } else {
      return false;
    }
  }
  //console.log("depth:"+depth)
  let shareClick = function () {
    setShareOpen(!shareOpen);
  }

  let arrowLeft = (<span className="icon-wrap"><IconButton size="small" aria-label="delete" ><ArrowBackIcon fontSize="small" /></IconButton></span>);
  let arrowRight = (<span className="icon-wrap"><IconButton size="small" aria-label="delete" ><ArrowForwardIcon fontSize="small" /></IconButton></span>);
  let arrowUp = (<span className="icon-wrap"><IconButton size="small" aria-label="delete"  ><ArrowForwardIcon fontSize="small" /></IconButton></span>);
  let arrowDown = (<span className="icon-wrap"><IconButton size="small" aria-label="delete"  ><ArrowDownwardIcon fontSize="small" /></IconButton></span>);
  let autoArrange = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_AUTO_ARRANGE(data))}><AutorenewIcon fontSize="small" /></IconButton></span>)

  let plus = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_ADD_PLUS(data, state))}><PlaylistAddIcon fontSize="small" /></IconButton></span>);
  let done = (<span className="icon-wrap"><IconButton size="small" aria-label="delete"><DoneIcon fontSize="small" /></IconButton></span>);
  // let share=(<span className="icon-wrap on"><IconButton size="small" aria-label="delete" ><ShareIcon  fontSize="small"/></IconButton></span>);
  let deleteButton = (<span className="icon-wrap"><IconButton size="small" aria-label="delete"><DeleteOutlineIcon fontSize="small" /></IconButton></span>);
  let undo = (<span className="icon-wrap"><IconButton size="small" aria-label="delete"><UndoIcon fontSize="small" /></IconButton></span>);
  let redo = (<span className="icon-wrap"><IconButton size="small" aria-label="delete"><RedoIcon fontSize="small" /></IconButton></span>);

  if (availLeft(data, parentChild)) {
    arrowLeft = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_EDIT_NODE_LEFT(data))}><ArrowBackIcon fontSize="small" /></IconButton></span>);
  }
  if (availRight(data, parentChild)) {
    arrowRight = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_EDIT_NODE_RIGHT(data))} ><ArrowForwardIcon fontSize="small" /></IconButton></span>);
  }
  if (availUp(data, parentChild)) {
    arrowUp = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_EDIT_NODE_UP(data))} ><ArrowUpwardIcon fontSize="small" /></IconButton></span>);
  }
  if (availDown(data, parentChild)) {
    arrowDown = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_EDIT_NODE_DOWN(data))} ><ArrowDownwardIcon fontSize="small" /></IconButton></span>);
  }
  if (data == null) {
    plus = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_ADD_PLUS(state.tree.selected_page, state))}><PlaylistAddIcon fontSize="small" /></IconButton></span>);
  }
  if (data != null) {
    done = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_DONE(data, state))}><DoneIcon fontSize="small" /></IconButton></span>)
  }
  let share = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick="" onClick={() => shareClick()}><ShareIcon fontSize="small" /></IconButton></span>);
  if (data != null) {
    deleteButton = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_DELETE(data))} ><DeleteOutlineIcon fontSize="small" /></IconButton></span>);
  }
  if (state.tree.historyLength > 1 && state.tree.historyIndex > 0) {
    undo = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_HISTORY_UNDO(data))}><UndoIcon fontSize="small" /></IconButton></span>);
  }
  if (state.tree.historyIndex + 1 < state.tree.historyLength) {
    redo = (<span className="icon-wrap on"><IconButton size="small" aria-label="delete" onClick={() => dispatch(ACTION_TREE.TREE_HISTORY_REDO(data))}><RedoIcon fontSize="small" /></IconButton></span>);
  }
  //console.log("state.tree.historyIndex:"+state.tree.historyIndex+",state.tree.historyLength:"+state.tree.historyLength)
  useEffect(() => {
    let keyDownEvent = function (e) {
      console.log("keyDownEvent", e.key, 'e', e, 'data', data, 'state', state)
      switch (e.key) {
        case 'ArrowUp':
          if (e.metaKey || e.altKey) {
            dispatch(ACTION_TREE.TREE_EDIT_NODE_UP(data, state));
          } else {
            dispatch(ACTION_TREE.TREE_EDIT_ARROW_UP(data, state));
          }
          break;
        case 'ArrowDown':
          if (e.metaKey || e.altKey) {
            dispatch(ACTION_TREE.TREE_EDIT_NODE_DOWN(data, state));
          } else {
            dispatch(ACTION_TREE.TREE_EDIT_ARROW_DOWN(data, state));
          }
          break;
        case 'ArrowLeft':
          if (e.metaKey || e.altKey) {
            dispatch(ACTION_TREE.TREE_EDIT_NODE_LEFT(data, state));
          }
          break;
        case 'ArrowRight':
          if (e.metaKey || e.altKey) {
            dispatch(ACTION_TREE.TREE_EDIT_NODE_RIGHT(data, state));
          }
          break;
        case 'PageUp':
          dispatch(ACTION_TREE.TREE_EDIT_NODE_UP(data, state));
          break;
        case 'PageDown':
          dispatch(ACTION_TREE.TREE_EDIT_NODE_DOWN(data, state));

          break;
        case 'Tab':
          e.preventDefault();
          if (e.shiftKey) {
            dispatch(ACTION_TREE.TREE_EDIT_NODE_LEFT(data, state));
          } else {
            dispatch(ACTION_TREE.TREE_EDIT_NODE_RIGHT(data, state));
          }
          break;
        case 'Delete':
          if (e.shiftKey || e.metaKey) {
            dispatch(ACTION_TREE.TREE_DELETE(data, state));
          }

        case 'z':
          if (e.metaKey && e.shiftKey) {
            console.log('Redo triggered');
            // 되돌리기 작업 실행
            dispatch(ACTION_TREE.TREE_HISTORY_REDO(data))
          }else if(e.ctrlKey && e.shiftKey){
            console.log('Redo triggered');
            // 되돌리기 작업 실행
            dispatch(ACTION_TREE.TREE_HISTORY_REDO(data))
          }else{
            if (e.ctrlKey || e.metaKey) {
              console.log('Undo triggered');
              // 되돌리기 작업 실행
              dispatch(ACTION_TREE.TREE_HISTORY_UNDO(data))
            }
          }
          break;
        case 'y':
          if (e.ctrlKey ) {
            console.log('Redo triggered');
            // 되돌리기 작업 실행
            dispatch(ACTION_TREE.TREE_HISTORY_REDO(data))
          }
          break;

      }
      return false
    }
    document.addEventListener('keydown', keyDownEvent)

    return () => {
      document.removeEventListener('keydown', keyDownEvent
      )
    }
  }, [data, state])
  return (
    <div className="tool-editor">
      <div className="tool-cont">
        {plus}
        {/* {done} */}
        {/* {share} */}
        {/* <ShareTool pOpen={shareOpen}/>  */}
        <ShareToolMenu />
        {autoArrange}
        {/* {deleteButton} */}
        {undo}
        {redo}

        {arrowLeft}
        {arrowRight}
        {arrowUp}
        {arrowDown}
        {/* <span className="icon-wrap"><IconButton size="small" aria-label="delete" onClick={()=>dispatch(ACTION_TREE.TREE_EDIT_NODE_LEFT(data))}><ArrowBackIcon/></IconButton></span>
            <span className="icon-wrap"><IconButton size="small" aria-label="delete" onClick={()=>dispatch(ACTION_TREE.TREE_EDIT_NODE_RIGHT(data))} ><ArrowForwardIcon/></IconButton></span>
            <span className="icon-wrap"><IconButton size="small" aria-label="delete" onClick={()=>dispatch(ACTION_TREE.TREE_EDIT_NODE_RIGHT(data))} ><ArrowForwardIcon/></IconButton></span>
            <span className="icon-wrap"><IconButton size="small" aria-label="delete" onClick={()=>dispatch(ACTION_TREE.TREE_EDIT_NODE_DOWN(data))} ><ArrowDownwardIcon/></IconButton></span> */}

      </div>


    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log("----------mapStateToProps ToolEditor");
  const datas = state.tree.data_root
  const getParentChild = function (ary, id) {
    let length = ary.length;
    let rtAry = null;

    for (let z = 0; z < length; z++) {
      let item = ary[z];
      if (item.id == id) {
        return ary;
      } else {
        rtAry = getParentChild(item.children, id)
        if (rtAry != null) {
          return rtAry
        }
      }
    }
    return null;
  }
  const getDepth = function (ary, id, firstDepth) {
    let length = ary.length;
    let rtAry = null;

    for (let z = 0; z < length; z++) {
      let item = ary[z];
      if (item.id == id) {
        return firstDepth;
      } else {
        rtAry = getDepth(item.children, id, firstDepth + 1)
        if (rtAry != null) {
          return rtAry
        }
      }
    }
    return null;
  }
  let parentChild, depth
  if (state.tree.selected_tree_data != null) {
    parentChild = getParentChild(state.tree.data_root, state.tree.selected_tree_data.id)
    depth = getDepth(state.tree.data_root, state.tree.selected_tree_data.id, 0)
  } else {

  }
  //console.log("statedata")
  //console.log(state)
  return {
    data: state.tree.selected_tree_data,
    state: state,
    state_login: state.login,
    state_tool: state.tool,
    parentChild: parentChild,
    depth: depth,
    state_tree: state.tree,


  };
}


export default connect(
  mapStateToProps
)(Temp)



