import UserVO from '../components/view/login/UserVO'; 
import {ACTION_LOGIN } from '../actions/ActionLogin'
let initialState = {
    user:new UserVO({}),
    savedUserData:{},
}
let cloneState = function (state) {
    let obj = Object.assign({}, state)
    obj.user = new UserVO(state.user);
    return obj;
}

const reducer = (state = initialState, action) => {

    console.log("reducer login reducer:"+action.type)
    //console.log(state);
    let clone = cloneState(state); 
    //console.log(action)
    switch (action.type) {
        case ACTION_LOGIN.TYPE.LOGIN_SUCCESS:
            clone.user = new UserVO(action.user)
            return clone;
        case ACTION_LOGIN.TYPE.USER_DATA_INIT:
        console.log("USER_DATA_INIT")
        console.log(action);
            clone.savedUserData = action.data;
            return clone;
     
        default:
            return clone;
    }
}

export default reducer
