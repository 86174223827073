import React, { useEffect } from 'react';
import Contents from './Contents';
function App() {
    // useEffect(() => {
    //     document.addEventListener('keydown', (event) => {
    //         if (event.key === 'Tab') {
    //             event.preventDefault(); // Tab 키의 기본 동작 방지
    //             console.log('Tab key press detected, but focus is not changing');
    //             // 여기에 원하는 다른 동작을 작성할 수 있습니다.
    //         }
    //     });
    // })
    const isApp = () => {
        // return true;
        if (window.webkit || window.Android) {
            if (window.Android) {
                let agent = window.navigator.userAgent;
                let c = "moshview";
                if (agent.indexOf(c) != -1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                // 여기가 ios app
                if (window.location.href.indexOf("todo.mosh.kr") != -1) {
                    window.location.href = "https://todo-mosh-kr-1fe63.firebaseapp.com/"
                }
                return true;
            }
        }
        return false;
    }
    let is_app = ""
    if (isApp()) {
        is_app = " is_app"
    }
    return (
        <div className={"App mobile" + is_app}>
            {/* <div className="App "> */}
            <Contents />
        </div>
    );
}

export default App;
