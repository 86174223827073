
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Toast from './Toast.scss'
import AlarmIcon from '@material-ui/icons/Alarm';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import { connect } from 'react-redux'
import {ACTION_DIALOG} from '~/actions/ActionDialog'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
  },
}));

function Temp({state,dispatch}) {
  const classes = useStyles();
//   const [open, setOpen] = React.useState(true);
  const closeAlert=()=>{
    ACTION_DIALOG.TOAST_SHOW(dispatch, null, false);
  }
  const getIcon=()=>{
    let icon = state.dialog.toast_icon
    if(icon=="alarm"){
      return(
        <AlarmIcon fontSize="inherit"/>
      )
    }else if(icon=="alarmoff"){
      return(
        <AlarmOffIcon fontSize="inherit"/>
      )
    }else{
      return(
        <CheckCircleOutlineIcon fontSize="inherit"/>
      )

    }
  }
  return (
      <div className="toast-wrap">
        <div className={classes.root}>
            <Collapse in={state.dialog.toast_open}>
                <Alert
                icon={getIcon()}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        // setOpen(false);
                        closeAlert();
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                >
                {state.dialog.toast_text}
                </Alert>
            </Collapse>
            {/* <Button
                disabled={open}
                variant="outlined"
                onClick={() => {
                setOpen(true);
                }}
            >
                Re-open
            </Button> */}
        </div>
      </div>
   
  );
}
const mapStateToProps = (state) => {
    return {state};
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}
export default connect(
    mapStateToProps,
)(Temp)



 