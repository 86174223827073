import { ACTION_WEBAPP } from '../actions/ActionWebApp'

let initialState = {
    // show_tool:true
    LOCAL_NOTIFICATION_TIME_SHOW: false,
    LOCAL_NOTIFICATION_TIME_SHOW_ANI: false,

}
let cloneState = function (state) {
    let obj = Object.assign({}, state)
    return obj;
}
const getIosHandler = function () {
    return window.webkit.messageHandlers;
}

const reducer = (state = initialState, action) => {

    //console.log("reducer tool reducer:"+action.type)
    //console.log(state);
    let clone = cloneState(state);
    //console.log(action)
    switch (action.type) {
        case ACTION_WEBAPP.TYPE.SHARE_TEXT:
            if (window.Android) {
                window.Android.shareTodoText(action.str)
            }
            if (window.webkit) {
                getIosHandler().shareTodoText.postMessage(action.str)
            }
            return clone;
        case ACTION_WEBAPP.TYPE.SHARE_IMAGE:
            console.log("...image")
            console.log(action.bitmap)
            if (window.Android) {
                window.Android.shareTodoImage(action.bitmap)
            }
            if (window.webkit) {
                getIosHandler().shareTodoImage.postMessage(action.bitmap);
            }
            return clone;
        case ACTION_WEBAPP.TYPE.LOCAL_NOTIFICATION_TIME_SHOW:
            clone.LOCAL_NOTIFICATION_TIME_SHOW = action.bool;
            if (action.bool) {
            }
            return clone;
            break;
        case ACTION_WEBAPP.TYPE.LOCAL_NOTIFICATION_TIME_SHOW_ANI:
            clone.LOCAL_NOTIFICATION_TIME_SHOW_ANI = action.bool;
            return clone;
            break;
        case ACTION_WEBAPP.TYPE.LOCAL_NOTIFICATION_ADD:
            console.log("-------action.data")
            console.log(action.data)
            let data = action.data;
            let date = new Date(action.data.local_push_noti_time);
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            let hour = date.getHours()
            let min = date.getMinutes()
            let sec = date.getSeconds()
            let prev_noti_id={
                noti_id:action.data.noti_id
            }
            data.alarm_date = year + "/" + month + "/" + day + "/" + hour + "/" + min + "/" + sec
            data.noti_id= action.noti_id;
            console.log("----notificationtest action.noti_id",action.noti_id)
            console.log("----notificationtest prev_noti_id", prev_noti_id)
            if (window.webkit) {
                getIosHandler().LOCAL_NOTIFICATION_ADD.postMessage(JSON.stringify(data));
            }
            if (window.Android) {
                if(prev_noti_id.noti_id){
                    window.Android.LOCAL_NOTIFICATION_REMOVE(JSON.stringify(prev_noti_id))
                }
 
                window.Android.LOCAL_NOTIFICATION_ADD(JSON.stringify(data))
                
            }
            return clone;
            break;
        case ACTION_WEBAPP.TYPE.LOCAL_NOTIFICATION_REMOVE:
            if (window.webkit) {
                getIosHandler().LOCAL_NOTIFICATION_REMOVE.postMessage(JSON.stringify(action.data));
            }
            if (window.Android) {
                window.Android.LOCAL_NOTIFICATION_REMOVE(JSON.stringify(action.data)) 
            }
            return clone;
            break;
        default:
            return clone;
    }
}

export default reducer
