class Temp  {
    constructor({displayName=null,email=null,emailVerified=null,phoneNumber=null,photoURL=null,uid=null,accessToken=null,providerData=null}){
        this.displayName = displayName
        this.email = email
        this.emailVerified = emailVerified
        this.phoneNumber = phoneNumber
        this.photoURL = photoURL
        this.uid = uid
        this.accessToken = accessToken
        this.providerData = providerData
    }
    copy({displayName=null,email=null,emailVerified=null,phoneNumber=null,photoURL=null,uid=null,accessToken=null,providerData=null}){
        this.displayName = displayName
        this.email = email
        this.emailVerified = emailVerified
        this.phoneNumber = phoneNumber
        this.photoURL = photoURL
        this.uid = uid
        this.accessToken = accessToken
        this.providerData = providerData
    }
    clone(){
        return new Temp({displayName:this.displayName,email:this.email, emailVerified:this.emailVerified,phoneNumber:this.phoneNumber, photoURL:this.photoURL,uid:this.uid,accessToken:this.accessToken,providerData:this.providerData })
    }
}
export default Temp;
