
import axios from 'axios';



export const CONFIG_INIT = (data) => ({
    type: 'CONFIG_INIT',
    data
})
export const CONFIG_SAVE = (uid) => ({
    type: 'CONFIG_SAVE',
    uid
})
export const CONFIG_CHANGE = (data) => ({
    type: 'CONFIG_CHANGE',
    data
})
export const RUN_CONFIG_REQUEST = (dispatch,uid) => {
    let count=0;
    const requestConfigData=(uid)=>{
        // return
        let THIS = this;
        axios.get('/api/todo/config', {
            params: {
                uid: uid
            }
        })
        .then(function (response) {
            dispatch(CONFIG_INIT(response.data))
        })
        .catch(function (error) {
            //console.log(error);
            count++;
            if(count<6){
                requestConfigData(uid);
            }
        });
    }
    requestConfigData(uid);
}


export const ACTION_CONFIG={
    TYPE:{
        CONFIG_INIT:'CONFIG_INIT',
        CONFIG_SAVE:'CONFIG_SAVE',
        CONFIG_CHANGE:'CONFIG_CHANGE',
    },
    RUN_CONFIG_REQUEST:RUN_CONFIG_REQUEST,

    CONFIG_INIT :CONFIG_INIT,
    CONFIG_SAVE :CONFIG_SAVE,
    CONFIG_CHANGE:CONFIG_CHANGE,

}

