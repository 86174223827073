import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import PersonIcon from '@material-ui/icons/Person';
import "./BuildInfo.scss";

import { connect } from 'react-redux'
;
import SettingHeader from '../SettingHeader';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,

  },
  list_radio: {
    // paddingTop:"13px",
    // paddingBottom:"13px"
    // height:"32px"
    paddingTop: "5px",
    paddingBottom: "5px"

  }
}));


function Temp({ dispatch,state_login, state_config, destroy }) {
  const classes = useStyles();

  const appversion=()=>{
    // return true;
    if(window.webkit||window.Android){
        let agent = window.navigator.userAgent;
        let c = "moshview";
        if(agent.indexOf(c)!=-1){
            // return true;
            let ary = agent.split(" ");
            for(var z=0; z<ary.length; z++){
                let d = ary[z].split("/")
                if(d[0]==c){
                    return d[1]
                }
            }
            return 0
        }else{
            return 0;
        }
       
    }
    return 0;
}

  let deviceStr=appversion();

  return (
    <div className={classes.root}>
      <SettingHeader title="버전 정보" back={true}/>
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button  >
          <ListItemIcon>
            <LanguageIcon /> 
          </ListItemIcon>
          <ListItemText primary="w1.08" />
        </ListItem>
        <ListItem button  >
          <ListItemIcon> 
            <PermDeviceInformationIcon /> 
          </ListItemIcon>
          <ListItemText primary={"d"+deviceStr} />
        </ListItem>
        </List>
        <div className="version-info"><b>1.0.10 버전 업데이트 내용(2024.10.31)</b>
        <br/>
        맥 환경<br/>
        command + z 히스토리 백(완료)<br/>
        command + shift + z 리로드 앞(완료)<br/>
        <br/>
        윈도우 환경<br/>
        ctrl + z 히스토리 백(완료)<br/>
        ctrl + shift + z 리로드 앞(완료)<br/>
        <br />
        공통<br/>
        커서 이동 버튼 누르면 이동(완료)<br/>
      
        </div>
        <br/>
        <br/>
        <div className="version-info"><b>1.0.9 버전 업데이트 내용(2024.10.30)</b>
        <br/>
     
        PageUp 키 누르면 위로 이동(완료)<br/>
        PageDown 키 누르면 아래로 이동(완료)<br/>
        </div>
        <br/>
        <br/>
        <div className="version-info"><b>1.0.8 버전 업데이트 내용(2024.10.24)</b>
        <br/>
    
        안녕하세요. <br/>
        개발자 모슈입니다.<br/>
        오랜만에 업데이트를 했습니다. <br/>
        https://mosh.kr <br/>
        <br/>
        pc 탭하면 들여쓰기(완료)<br/>
        쉬프트 탭하면 바깥으로(완료)<br/>
        크롬에서 엔터 두번됨(한글의 경우에만)(완료)<br/>
        윈도우 shift+delete, mac command+delete 할 경우 삭제(완료)<br/>
        두번 선택해야 포커스됨(완료)<br/>
        </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state)
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,
    state_config: state.config,
  };
}

export default connect(
  mapStateToProps,
)(Temp)


