import React, { Component } from 'react';
import { Route } from "react-router-dom";
import BoardManagerWrap from "../board/BoardManagerWrap"
import Login from "../view/login/Login"
import './RouterWrap.scss'
import SettingWrap from './setting/SettingWrap';
import Skin from './setting/skin/Skin';
import Account from './setting/account/Account';
import AccountInfo from './setting/account/AccountInfo';
import BuildInfo from './setting/build/BuildInfo';

class Container extends Component { 
    constructor(props) {
        super(props);
    } 
    render() {
        return (
            <div className="section-container">
                <Route exact path="/todo" component={BoardManagerWrap} ></Route>
                <Route exact path="/setting/login" component={Login} ></Route>
                <Route exact path="/setting" component={SettingWrap} ></Route>
                <Route exact path="/setting/skin" component={Skin} ></Route>
                <Route exact path="/setting/account" component={Account} ></Route>
                <Route exact path="/setting/account_info" component={AccountInfo} ></Route>
                <Route exact path="/setting/build_info" component={BuildInfo} ></Route>

            </div> 
        );
    }
}
 
export default Container;
