import {ACTION_CONFIG} from '../actions/ActionConfig'
import axios from 'axios';
import VOConfig from '../components/layout/setting/VOConfig'

let initialState = {
    config:new VOConfig({}),
    loaded:false
}
let cloneState = function (state) {
    let obj = Object.assign({}, state) 
    obj.config = obj.config.clone();
    return obj;
}
let firstConfig;

const saveConfig =(uid,data)=>{
    let THIS = this;
    let count = 0;
    const save=(uid,data)=>{
        axios.post('/api/todo/insert-config', {
            uid: uid,
            data:data
        })
        .then(function (response) {
        })
        .catch(function (error) {
            count++;
            if(count<6){
                save(uid,data);
            }
        });
    }
    save(uid,data);
}
const reducer = (state = initialState, action) => {

    //console.log(action)
    let clone = cloneState(state); 
    switch (action.type) {
        case ACTION_CONFIG.TYPE.CONFIG_INIT:
            if(action.data.success===false){
                firstConfig = clone.config.clone();;
                clone.loaded=true;
                return clone;
            }else{
                clone.config = new VOConfig(action.data.config)
                firstConfig = clone.config.clone();
                clone.loaded=true;
                return clone;
            }
        case ACTION_CONFIG.TYPE.CONFIG_SAVE:
           
            if(firstConfig==undefined||firstConfig.equal(clone.config)){
            }else{
                saveConfig(action.uid, clone)
            }
            return clone;
        case ACTION_CONFIG.TYPE.CONFIG_CHANGE:
            // if(action.data.equal(clone)){
            // }else{
            //     clone.copy(action.data);
            //     saveConfig(action.uid, action.data)
            // }
            clone.config.copy(action.data.config);
            return clone;
        default:
            return clone;
    }
}

export default reducer
