import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'


import {ACTION_DIALOG} from '~/actions/ActionDialog';

function Temp({ dispatch,state_dialog }) {
  const handleClose = () => {
    // setOpen(false);
    dispatch(ACTION_DIALOG.DIALOG_CLOSE())
  };
  const handleConfirm=()=>{
    if(state_dialog.callbackSuccess) state_dialog.callbackSuccess();
    
    handleClose();
  }
  const handleCancle=()=>{
    handleClose();
  }
  const getMsg=()=>{
    let container=[];
    let ary = state_dialog.msg;
    let length = ary.length;
    console.log("length:"+length)
    for(let z=0; z<length; z++){
      container.push(
        <Typography key={"confirm msg"+z}>{ary[z]}</Typography>
      )
    }
    console.log("container")
    console.log(state_dialog.msg);
    console.log(container)
    if(container.length==0)return(null);
    return (
      <div>{container}</div>
    )
  }

  return (
    <div>
    
      <Dialog
        open={state_dialog.show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getMsg()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancle} color="primary">
            {state_dialog.msg_cancle}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {state_dialog.msg_ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,
    state_config: state.config,
    state_dialog:state.dialog
  };
}

export default connect(
  mapStateToProps,
)(Temp)


