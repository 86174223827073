
export const DIALOG_OPEN = (msg,msg_ok, msg_cancle, callbackSuccess,callbackFail) => ({
    type: 'DIALOG_OPEN',
    msg, msg_ok, msg_cancle, callbackSuccess,callbackFail
})

export const DIALOG_CLOSE = (data) => ({
    type: 'DIALOG_CLOSE',
})
export const TOAST_OPEN = (text, open, icon) => ({
    type: 'TOAST_OPEN',
    text, open, icon
})

export const TOAST_SHOW = (dispatch,text,open,icon) => {
    if(open==null){
        open = true;
    } 
    if(open){
        dispatch(TOAST_OPEN(text,true,icon))
        setTimeout(()=>{
            dispatch(TOAST_OPEN(text,false,icon))
        },3000)
    }else{
        dispatch(TOAST_OPEN(text,false,icon))
    }
   
}



export const ACTION_DIALOG={
    TYPE:{
        DIALOG_OPEN:'DIALOG_OPEN',
        DIALOG_CLOSE:'DIALOG_CLOSE',
        TOAST_OPEN:'TOAST_OPEN',
        TOAST_SHOW:'TOAST_SHOW'
    },
    DIALOG_OPEN : DIALOG_OPEN,
    DIALOG_CLOSE:DIALOG_CLOSE,
    TOAST_OPEN:TOAST_OPEN,
    TOAST_SHOW:TOAST_SHOW
}