

export const setEnterLock = (event) => {
    window.globalEnterLock = true;
    setTimeout(() => {
        window.globalEnterLock = false;
    }, 100);
}
export const isEnterLock = () => {
    return window.globalEnterLock;
}