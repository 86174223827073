import React from 'react';
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'; 
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TREE_EDIT_DETAIL_CLOSE, TREE_EDIT_DETAIL_SAVE, TREE_INIT ,TREE_ADD_AT_FIRST} from '~/actions/ActionTree';

import "./BoardEditDialog.scss";

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    minWidth: 300
  }
}));


function Temp({ state_tree, dispatch }) {
  let vo = state_tree.selected_data;
  const classes = useStyles();
  const [title, setTitle] = React.useState(vo.extra_data.title)
  const [description, setDescription] = React.useState(vo.extra_data.description)
  const [board_type, setBoardType] = React.useState(vo.extra_data.board_type)
  const [link_target, setLinkTarget] = React.useState(vo.extra_data.link_target)
  const [link_url, setLinkUrl] = React.useState(vo.extra_data.link_url)
  const [open, setOpenType] = React.useState(vo.extra_data.open)

  const handleClose = () => {
    // onDialogCancel();
    dispatch(TREE_EDIT_DETAIL_CLOSE());
  };
  const handleSave = () => {
    // onDialogSave({ title, description, board_type, link_target, link_url, open });
    dispatch(TREE_EDIT_DETAIL_SAVE({ title, description, board_type, link_target, link_url, open }));
  }
  const handleTitle = (e) => {
    setTitle(e.target.value)
  }
  const handleDescription = (e) => {
    setDescription(e.target.value)
  }
  const handleBoardType = (e) => {
    setBoardType(e.target.value)
  }
  const handleLinkTarget = (e) => {
    setLinkTarget(e.target.value)
  }
  const handleLinkUrl = (e) => {
    setLinkUrl(e.target.value)
  }
  const handleOpenType = (e) => {
    setOpenType(e.target.value)
  }


  return (
    // this.title = props.title
    // this.description =props.description;

    // this.board_type = props.board_type
    // this.link_target = props.link_target
    // // 사이트내 이동, self, blank
    // this.link_url = props.link_url
    // this.open = props.open
    // // 공개, 비공개, 링크 공개, 유저 공개
    // this.open_user=[];
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Board Edit</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            className={classes.title}
            margin="dense"
            id="name"
            label="title"
            type="email"
            fullWidth
            onChange={handleTitle}
            defaultValue={vo.extra_data.title}
          />
          <TextField
            margin="dense"
            id="name"
            label="description"
            type="email"
            fullWidth
            onChange={handleDescription}
            defaultValue={vo.extra_data.description}
          />
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const mapStateToProps = (state) => {
  //console.log("----------mapStateToProps");
  return {
      state_tree: state.tree,
      state_login: state.login,
      state_tool: state.tool,

      // datas:state.TreeReducer.data_root,
      // propExpanded:state.TreeReducer.propExpanded,
      // show_edit_dialog:state.TreeReducer.show_edit_dialog,
      // show_tool:state.ToolReducer.show_tool
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  // treeNodeOnChange: () => dispatch(setVisibilityFilter(ownProps.filter))
})


export default connect(
  mapStateToProps
)(Temp)
