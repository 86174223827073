import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import SecurityIcon from '@material-ui/icons/Security';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { Link } from 'react-router-dom';
// import firebase from 'firebase/app';
import * as firebase from 'firebase/app';


import './Account.scss';
import { connect } from 'react-redux'
import {ACTION_CONFIG} from'~/actions/ActionConfig'
import SettingHeader from '../SettingHeader';
import { getAuth } from 'firebase/auth';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,

  },
  list_radio: {
    // paddingTop:"13px",
    // paddingBottom:"13px"
    // height:"32px"
    paddingTop: "5px",
    paddingBottom: "5px"

  }
}));


function Temp({ dispatch,state_login, state_config, destroy }) {
  const classes = useStyles();


  //console.log("setting destroy:",destroy)
  //console.log("setting...")

  let cls_logout = ""
  if (state_login.user.uid == null) {
    cls_logout = "deActive"
  }
  console.log("user login info");
  console.log(state_login);
  console.log(state_config);
  let getInfo0=()=>{
    if(state_login.user.uid&&state_login.user.providerData){
      return  "가입 방법 : "+state_login.user.providerData[0].providerId;
    }else{
      return ""
    }
   
  }
  let getInfo1=()=>{
    if(state_login.user.uid&&state_login.user.email){
    
;    return  "이메일 : "+state_login.user.email
    }else{
      return ""
    }
  }
  let getInfo2=()=>{
    if(state_login.savedUserData.extra_data){
  let time =       state_login.savedUserData.extra_data.created.time
  let st = new Date(time);
  let month = st.getMonth()+1;
  let d = st.getDate(); 
;    return  "가입일 : "+st.getFullYear() +"년 "+ month+"월 "+d+" 일";
    }else{
      return ""
    }
  }

  return (
    <div className={classes.root}>
      <SettingHeader title="계정 정보" back={true}/>

      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem >
          <ListItemText primary={getInfo2()} />
        </ListItem>
        <ListItem>
          <ListItemText primary={getInfo0()} />
        </ListItem>
        <ListItem >
          <ListItemText primary={getInfo1()} />
        </ListItem>
      </List>
      {/* <Divider /> */}
     

    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state)
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,
    state_config: state.config
  };
}

export default connect(
  mapStateToProps,
)(Temp)


