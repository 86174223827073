class Temp  {
    constructor({title=null,description=null,board_type="board",link_target="_self",link_url="",open=0,open_user=[],password=null}){
        this.title = title
        this.description =description;
        this.board_type = board_type
        // board: 게시판 타입, link: 링크 타입
        this.link_target = link_target
        // 사이트내 이동, self, blank
        this.link_url = link_url
        this.open = open
        // 공개, 비공개, 링크 공개, 유저 공개
        this.open_user=open_user;
        this.password=password;
    }
    setTitle(title){
        this.title=  title;
    }
    copy({title=null,description=null,board_type="board",link_target="_self",link_url=null,open=0,open_user=[],password=null}){
        this.title = title
        this.description =description;
        this.board_type = board_type
        this.link_target = link_target
        this.link_url = link_url
        this.open = open
        this.password = password
        this.open_user=open_user.slice();
    }
    clone(){
        return new Temp({title:this.title,description:this.description, board_type:this.board_type,link_target:this.link_target, link_url:this.link_url,open:this.open,open_user:this.open_user.slice(),password:this.password })
    }
}
export default Temp;
