import React, { Component } from 'react';
import './LnbWrap.scss';
import Lnb from './Lnb'
import Logout from '../view/login/Logout'
import { connect } from 'react-redux'
import ToolHeader from './ToolHeader'
// import firebase from 'firebase/app';
import * as firebase from 'firebase/app';


import { TREE_SELECTED_CHANGE } from '~/actions/ActionTree';
import { getAuth } from 'firebase/auth';


class Temp extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            // links:{},
            logoutMenu: false,
            appBarVisible: true
        }
    }


    componentDidMount() {
    }
    onMenuToggle() {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }
    onLogoutOpen(bool) {
        this.setState({
            logoutMenu: bool
        })
    }
    onLogout() {
        const auth = getAuth();
        auth.signOut().then(function () {
            //console.log('Sign-out successful.')
            localStorage.clear();
            sessionStorage.clear();
        }).catch(function (error) {
            // An error happened.
        });
    }
    
    onTreeSelectChange(data) {
        //console.log("onTreeSelectChange")
        this.props.onTreeSelectChange(data)
        this.headerReset();
    }
    headerReset(){    
        let THIS = this;
        this.setState({
            appBarVisible: false
        })
        let fnc = function () {
            THIS.setState({ appBarVisible: true })
        }
        setTimeout(fnc, 1);
    }
    
    render() {
        let t = null;
        if(this.props.state_tree.selected_page){
            t = this.props.state_tree.selected_page.title
        }
        console.log("tt:", t)
        return (
            <div className="lnb-wrap">
                {
                    t != null && this.state.appBarVisible &&
                    <ToolHeader  headerReset={()=>this.headerReset()} save={() => this.save()}  currentTitle={t} onMenuToggle={() => this.onMenuToggle()}  />
                }
                <Lnb  onTreeSelectChange={(data) => this.onTreeSelectChange(data)} onMenuToggle={() => this.onMenuToggle()} popen={this.state.openMenu}  />
                {this.state.logoutMenu &&
                    <Logout onLogout={() => this.onLogout()} onLogoutOpen={(bool) => this.onLogoutOpen(bool)}></Logout>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        state_tree: state.tree,
        state_login: state.login,
        state_tool: state.tool,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onTreeSelectChange: (data) => dispatch(TREE_SELECTED_CHANGE(data)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Temp)



