import React,{useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import {ACTION_CONFIG} from'~/actions/ActionConfig'

import './Skin.scss';
import SettingHeader from "~/components/layout/setting/SettingHeader";

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

function Temp({state_config,dispatch}) {
  const [expanded, setExpanded] = React.useState('panel1');
  const [color, set_color] = React.useState(state_config.config.skin0VO);
  const changeColor=(e)=>{
    // func
    //console.log("color:"+e.target.value);
    // func(e.target.value);
    set_color({...color,[e.target.name]:e.target.value});
    changeData(e.target.name, e.target.value);
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const changeData=(name, value)=>{
    state_config.config.skin0VO[name] = value;
    dispatch(ACTION_CONFIG.CONFIG_CHANGE(state_config))
  }
  return (
    <div className="skin-list">
      <SettingHeader title="스킨 설정" back={true}/>

      <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>기본 스킨</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <span className="wrap-color">
              <div className="line-wrap">
                <input className="color-pal" type="color" name="first_text_color" onChange={(e)=>changeColor(e)} defaultValue={color.first_text_color}></input><span className="wrap-c-2 tf">첫번째 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="first_done_text_color" onChange={(e)=>changeColor(e)} defaultValue={color.first_done_text_color}></input><span className="wrap-c-2 tf">첫번째 완료 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="first_done_bg_color" onChange={(e)=>changeColor(e)} defaultValue={color.first_done_bg_color}></input><span className="wrap-c-2 tf">첫번째 문자 배경 색</span><br />
              </div>
              <br />
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_text_color" onChange={(e)=>changeColor(e)} defaultValue={color.next_text_color}></input><span className="wrap-c-2 tf">두번째 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_done_text_color" onChange={(e)=>changeColor(e)} defaultValue={color.next_done_text_color}></input><span className="wrap-c-2 tf">두번째 완료 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_done_bg_color" onChange={(e)=>changeColor(e)} defaultValue={color.next_done_bg_color}></input><span className="wrap-c-2 tf">두번째 문자 배경 색</span><br />
              </div>
              <br />
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_text_color_3" onChange={(e)=>changeColor(e)} defaultValue={color.next_text_color_3}></input><span className="wrap-c-2 tf">세번째 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_done_text_color_3" onChange={(e)=>changeColor(e)} defaultValue={color.next_done_text_color_3}></input><span className="wrap-c-2 tf">세번째 완료 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_done_bg_color_3" onChange={(e)=>changeColor(e)} defaultValue={color.next_done_bg_color_3}></input><span className="wrap-c-2 tf">세번째 문자 배경 색</span><br />
              </div>
              <br />
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_text_color_4" onChange={(e)=>changeColor(e)} defaultValue={color.next_text_color_4}></input><span className="wrap-c-2 tf">네번째 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_done_text_color_4" onChange={(e)=>changeColor(e)} defaultValue={color.next_done_text_color_4}></input><span className="wrap-c-2 tf">네번째 완료 문자 색</span><br />
              </div>
              <div className="line-wrap">
                <input className="color-pal" type="color" name="next_done_bg_color_4" onChange={(e)=>changeColor(e)} defaultValue={color.next_done_bg_color_4}></input><span className="wrap-c-2 tf">네번째 문자 배경 색</span><br />
              </div>
            </span>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
} 

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,
    state_config: state.config
  };
}

export default connect(
  mapStateToProps,
)(Temp)


